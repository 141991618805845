import { I18n } from 'vendor/i18n-js/index.js.erb'
import Rails from '@rails/ujs'
import * as Turbo from "@hotwired/turbo"
import * as ActiveStorage from '@rails/activestorage'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const E = window.E = window.E || { }

// Enable Rails UJS; See: http://guides.rubyonrails.org/working_with_javascript_in_rails.html
E.Rails = Rails
E.Rails.start()

// Enable Direct Uploads via Active Storage; See: https://guides.rubyonrails.org/active_storage_overview.html#direct-uploads
ActiveStorage.start()

E.Turbo = Turbo

E.Turbo.StreamActions.redirect = function () {
  E.Turbo.visit(this.target);
};

// Stimulus:
E.application = Application.start()
E.context = require.context("../controllers", true, /\.js$/)
E.application.load(definitionsFromContext(E.context))
